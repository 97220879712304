#global-header {
  position: absolute;
  top: -100px;
  width: 100%;
  line-height: 1;
  z-index: 90;
  margin-top: 100px;
  .inner {
    position: relative;
    width: 100%;
    h1 {
	    font-size: 24px;
      position: absolute;
      line-height: 0;
      top: 13px;
      left: 10px;
      z-index: 100;
      width: 180px;
      margin-bottom: 0;
    }
    .logo-header {
	    max-height: 28px;
    } 
    nav {
      position: absolute;
      top: -500px;
      background: rgba($white,0.96);
      width: 100%;
      text-align: center;
      padding-top: 0;
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      ul {
        list-style: none;
        margin: 0;
        font-size: 14px;
        text-align: center;
        li {
          display: block;
          border-bottom: 1px solid $light-gray;
          font-weight: bold;
          letter-spacing: 2px;
          a, span {
            width: 100%;
            display: block;
            padding: 18px 0;
          }
          span {
	          color: $anchor-color;
	          position: relative;
	          cursor: pointer;
	          &:after {
		          font-family: FontAwesome;
		          content: "\f078";
		          color: $medium-gray;
		          position: absolute;
		          right: 10px;
	          }
          }
          ul {
	          display: none;
	          li {
		          &:first-child {
			          border-top: 1px solid $light-gray;
			          border-bottom-color: $white;
		          }
		          &:last-child {
			          border: none;
		          }
		          a {
			          background: rgba($primary-color, 0.05);
		          }
	          }
          }
        }
      }
    }
  }
  #nav-toggle {
    position: absolute;
    right: 14px;
    top: 18px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    z-index: 100;
    div {
      position: relative;
    }
    span {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: $black;
      left: 0;
      -webkit-transition: .35s ease-in-out;
      -moz-transition: .35s ease-in-out;
      transition: .35s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 10px;
      }
      &:nth-child(3) {
        top: 20px;
      }
    }
  }
  #mobile-head {
    width: 100%;
    height: 56px;
    z-index: 90;
    position: absolute;
  }
  
  
  &.fixed {
    position: fixed;
    background: rgba($white, 0.95);
    margin-top: 0;
    top: 0;
    height: 56px;
    transition: top 0.65s ease-in;
    -webkit-transition: top 0.65s ease-in;
    -moz-transition: top 0.65s ease-in;
  }
  
  @include breakpoint(medium down) {
	  &.open {
	    nav {
	      /* #global-nav top + #mobile-head height */
	      -moz-transform: translateY(556px);
	      -webkit-transform: translateY(556px);
	      transform: translateY(556px);
	    }
	    #nav-toggle span:nth-child(1) {
	      top: 11px;
	      -webkit-transform: rotate(315deg);
	      -moz-transform: rotate(315deg);
	      transform: rotate(315deg);
	    }
	    #nav-toggle span:nth-child(2) {
	      width: 0;
	      left: 50%;
	    }
	    #nav-toggle span:nth-child(3) {
	      top: 11px;
	      -webkit-transform: rotate(-315deg);
	      -moz-transform: rotate(-315deg);
	      transform: rotate(-315deg);
	    }
	  }
  }
  
  @include breakpoint(large up) {
    #mobile-head {
	    display: none;
    }
    .inner {
	    padding-top: 24px;
	    h1 {
		    width: 240px;
		    top: 15px;
		    left: 18px;
	    }
	    .logo-header {
		    max-height: 38px;
	    }
	    nav {
		    position: static;
		    top: 0;
		    width: auto;
		    background: none;
		    text-align: right;
		    > ul {
			    text-align: right;
			    > li {
				    display: inline-block;
				    border-bottom: none;
				    > a, > span {
					    padding: 0 16px 6px;
					    color: $black;
					    &:after {
						    content: "";
						    width: 0;
						    transition: all 0.3s ease;
						    display: block;
						    height: 2px;
						    background: linear-gradient(45deg, $primary-color, $secondary-color);
					    }
					    &:hover {
						    color: $primary-color;
						    &:after {
							    width: 100%;
						    }
					    }
				    }
				    &.service {
					    position: relative;
					    height: 40px;
					    span {
						    &:after {
							    position: inherit;
							    right: 0;
						    }
					    }
					    ul {
						    width: 200%;
						    position: absolute;
						    top: 40px;
						    left: -50%;
						    li {
							    background: rgba($white, 0.96);
							    &:first-child {
								    border-top: none;
								    border-bottom: 1px solid $light-gray;
							    }
							    a {
								    background: rgba($primary-color, 0.05);
								    transition: all .5s;
								    &:hover {
									    background: rgba($primary-color, 0.2);
								    }
							    }
						    }
					    }
					    &:hover ul {
						    display: block;
					    }
				    }
			    }
		    }
	    }
    }
    &.fixed {
	    .inner {
		    padding-top: 16px;
		    h1 {
			    top: 8px;
		    }
	    }
    }
  }
}


// facebook & instagram
// --------------------

#fixed-menu {
	display: none;
	@include breakpoint(large up) {
		display: block;
		position: fixed;
		z-index: 10;
		right: 0;
		top: 100px;
		ul {
			list-style: none;
			margin: 0;
			li {
				margin-bottom: 10px;
			}
		}
		a {
			display: flex;
			border-radius: 10px 0 0 10px;
			color: $white;
			width: 44px;
			height: 44px;
			font-size: 24px;
			justify-content: center;
			align-items: center;
			&:hover {
				i {
					opacity: .7;
				}
			}
		}
		li.facebook {
			a {
				background: #3b5998;
			}
		}
		li.instagram {
			a {
				background: linear-gradient(45deg, #f50000, #b900b4, #1400c8);
			}
		}
	}
}

