// page template common
// --------------------

main.pages {
	padding-top: 70px;
	margin-bottom: 50px;
	header {
		margin-bottom: 40px;
		h2 {
			text-align: center;
			font-weight: 900;
			margin-bottom: 40px;
			span,small {
				display: block;
			}
			span {
				font-size: 46px;
				letter-spacing: 0.1rem;
				color: $secondary-color;
			}
			small {
				color: $black;
				font-size: 14px;
				&:before {
					display: block;
					content: "";
					height: 1px;
					width: 50px;
					background: $secondary-color;
					margin: 0 auto 20px;
				}
			}
		}
		p {
			@extend .container;
			@include breakpoint(large up) {
				text-align: center;
				line-height: 2.5;
				span {
					display: block;
				}
			}
		}
	}
	.subheading {
		text-align: center;
		font-weight: 900;
		font-size: 26px;
		margin-bottom: 40px;
		color: $secondary-color;
		margin-bottom: 20px;
		&:after {
			display: block;
			content: "";
			height: 1px;
			width: 50px;
			background: $secondary-color;
			margin: 0 auto;
			margin-top: 5px;
		}
		@include breakpoint(medium up) {
			font-size: 31px;
			margin-bottom: 40px;
		}
	}
	> section {
		&:not(:last-child) {
			margin-bottom: 70px;
		}
	}
	.section-image {
		width: 100%;
		height: 200px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		margin-bottom: 30px;
		@include breakpoint(medium up) {
			height: 534px;
			margin-bottom: 150px;
		}
	}
	
	@include breakpoint(medium up) {
		padding-top: 100px;
		margin-bottom: 150px;
		header {
			margin-bottom: 100px;
			h2 {
				span {
					font-size: 110px;
					letter-spacing: 0.5rem;
				}
				small {
					font-size: 16px;
					&:before {
						margin-bottom: 30px;
					}
				}
			}
		}
		.subhead {
			margin-bottom: 70px;
		}
		> section {
			&:not(:last-child) {
				margin-bottom: 150px;
			}
		}
	}
}


// ミッション
// --------------------

main#mission {
	section#section-mission {
		margin-bottom: 70px;
		.section-image {
			background-image: url(../images/mission/mission_image01.jpg);
		}
		figure {
			margin-bottom: 30px;
			img {
				width: 250px;
			}
			@include breakpoint(medium up) {
				margin-bottom: 70px;
				img {
					width: 500px;
				}
			}
		}
		ul.column-box {
			h2 {
				word-break: break-all;
			}
		}
		@include breakpoint(medium up) {
			margin-bottom: 150px;
		}
	}
	section#section-mind {
		h2 + p {
			@extend .container;
			margin-bottom: 10px;
			@include breakpoint(medium up) {
				margin-bottom: 50px;
				text-align: center;
				line-height: 2.5;
				span {
					display: block;
				}
			}
		}
		.section-image {
			background-image: url(../images/mission/mission_image02.jpg);
		}
	}
	ul.column-box {
		li {
			&:nth-child(2) {
		    -moz-transition-delay:400ms;
		    -webkit-transition-delay:400ms;
		    -o-transition-delay:400ms;
		    -ms-transition-delay:400ms;				
			}
			&:nth-child(3) {
		    -moz-transition-delay:600ms;
		    -webkit-transition-delay:600ms;
		    -o-transition-delay:600ms;
		    -ms-transition-delay:600ms;				
			}
		}
	}
}


// サービス共通スタイル
// --------------------

main#service-futsal, main#service-construction {
	header {
		text-align: center;
		h3 {
			font-size: 26px;
			border: 1px solid;
			display: inline-block;
			padding: 10px 20px;
			font-weight: bold;
			color: $secondary-color;
		}
		@include breakpoint(medium up) {
			h2 {
				margin-bottom: 70px;
			}
			h3 {
				font-size: 36px;
				padding: 20px 50px;
			}
		}
	}
	#section-introduction {
	  position: relative;
	  overflow: hidden;
	  padding: 20% 10px;
	  margin-bottom: -80px;
	  color: $white;
	  &:before {
		  content: '';
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 120%;
		  height: 80%;
		  margin: 3% -10% 0;
			background-color: lighten($black, 20%);
			background-image: linear-gradient(
		    -45deg,
		    transparent 25%,
		    $black 25%, $black 50%,
		    transparent 50%, transparent 75%,
		    $black 75%, $black
		  );
		  background-size: 4px 4px;
		  -webkit-transform-origin: right center;
		  -ms-transform-origin: right center;
		  transform-origin: right center;
		  -webkit-transform: rotate(-5deg);
		  -ms-transform: rotate(-5deg);
		  transform: rotate(-5deg);
		  z-index: -1;		  
	  }
	  &:after {
		  content: '';
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 120%;
		  height: 80%;
		  margin: 2% -10% 0;
		  background: $secondary-color;
		  -webkit-transform-origin: left center;
		  -ms-transform-origin: left center;
		  transform-origin: left center;
		  -webkit-transform: rotate(4deg);
		  -ms-transform: rotate(4deg);
		  transform: rotate(4deg);
		  z-index: -2;		  
		  
	  }
	  .inner {
		  box-sizing: boder-box;
		  width: 100%;
		  max-width: $global-width;
		  height: 100%;
		  margin: 0 auto;
		  padding-bottom: 120px;
	  }
	  h4 {
		  font-size: 20px;
		  font-weight: bold;
		  &:after {
			  content: "";
			  display: block;
			  height: 1px;
			  width: 100%;
			  background: linear-gradient(45deg, $primary-color, $secondary-color);
			  margin: 15px 0;
		  }
	  }
	  figure {
		  text-align: center;
		  img {
			  width: 100%;
			  max-width: 600px;
		  }
	  }
	  @include breakpoint(large up) {
		  margin-bottom: 0;
		  padding: 13% 10px 18%;
		  .inner {
			  overflow: hidden;
			  padding-bottom: 0;
			  > div, figure {
				  float: left;
			  }
			  > div {
				  width: 45%;
				  margin-right: 5%;
				  padding-top: 80px;
			  }
			  > figure {
				  width: 50%;
			  }
		  }
		  h4 {
			  font-size: 32px;
			  line-height: 1.6;
			  span {
				  display: block;
			  }
			  &:after {
				  margin: 30px 0;
			  }
		  }
		  p {
			  line-height: 2;
		  }
	  }
	}
	.column-contents {
		@extend .container;
		margin-bottom: 30px;
		figure {
			margin-bottom: 10px;
		}
		h5 {
			font-size: 18px;
			font-weight: bold;
			border-bottom: 1px dotted $dark-gray;
			text-align: center;
			padding-bottom: 5px;
			margin-bottom: 10px;
		}
		@include breakpoint(medium up) {
			display: table;
			max-width: $global-width;
			margin: 0 auto 60px;
			figure, > div {
				display: table-cell;
				vertical-align: middle;
				width: 50%;
			}
			figure {
				text-align: center;
				padding: 20px;
			}
			h5 {
				font-size: 26px;
				text-align: left;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}
			p {
				line-height: 2;
			}
		}
	}
	#section-contents {
		h4 + p {
			@extend .container;
			margin-bottom: 10px;
			@include breakpoint(medium up) {
				text-align: center;
				margin-bottom: 40px;
				line-height: 2;
				span {
					display: block;
				}
			}
		}
	}
	#noar-futsal-stage {
		h5 {
			text-align: center;
			margin-bottom: 20px;
			border: none;
		}
		figure img {
			width: 100%;
			max-width: 230px;
		}
		@include breakpoint(small only) {
			figure {
				width: 150px;
				margin: 0 auto 20px;
			}
			h5 img {
				width: 300px;
			}
		}
	}
	
	#section-locations {
		.locations {
			margin-bottom: 30px;
			> ul {
				list-style: none;
				@extend .container;
				text-align: center;
				> li {
					border: 2px solid $medium-gray;
					padding: 10px;
					margin-bottom: 8px;
				}
				a.btn {
					padding: 10px 50px;
				}
			}
			figure {
				display: none;
			}
			h5 {
				font-weight: bold;
				border-bottom: 1px dotted $medium-gray;
				padding-bottom: 5px;
			}
			p {
				text-align: left;
			}
			@include breakpoint(medium up) {
				width: 100%;
				background: $secondary-color;
				padding: 40px 10px;
				margin-bottom: 60px;
				> ul {
					overflow: hidden;
					> li {
						float: left;
						width: 32%;
						background: $white;
						border: none;
						padding-bottom: 20px;
						margin-bottom: 20px;
						&:not(:last-child) {
							margin-right: 2%;
						}
					}
				}
				figure {
					display: block;
					margin-bottom: 20px;
					img {
						width: 100%;
					}
				}
			}
		}
	}
}


// フットサル店舗運営
// --------------------

main#service-futsal {
	#section-contents {
		ul {
			padding: 0 10px;
			list-style: none;
			margin: 0 auto;
			text-align: center;
			li {
				border: 2px solid $medium-gray;
				padding: 10px;
				margin-bottom: 8px;
			}
			@include breakpoint(medium up) {
				max-width: $global-width;
				li {
					display: inline-block;
					width: 46%;
					margin: 0 1% 20px;
					padding: 25px;
				}
			}
			@include breakpoint(medium up) {
				li {
					font-size: 18px;
				}
			}
		}
	}
}


// 建設デザイン
// --------------------

main#service-construction {
	header {
		h3 {
			color: $primary-color;
		}
	}
	#section-introduction {
		&:after {
			background: $primary-color;
		}
	}
	#section-contents {
		ul.column-box {
			@extend .container;
			h2 {
				border-bottom: 1px dotted $medium-gray;
				padding-bottom: 5px;
				&:after {
					display: none;
				}
			}
			@include breakpoint(medium up) {
				h2 {
					padding-bottom: 10px;
					margin-bottom: 20px;
				}
			}
			> li {
				&:nth-child(2) {
			    -moz-transition-delay:400ms;
			    -webkit-transition-delay:400ms;
			    -o-transition-delay:400ms;
			    -ms-transition-delay:400ms;				
				}
				&:nth-child(3) {
			    -moz-transition-delay:600ms;
			    -webkit-transition-delay:600ms;
			    -o-transition-delay:600ms;
			    -ms-transition-delay:600ms;				
				}
				section {
					ul {
						list-style: none;
						margin-left: 0;
						li {
							display: inline;
							&:not(:last-child) {
								&:after {
									content: "/";
									margin: 0 6px;
									color: $medium-gray;
								}
							}
						}
					}
				}
			}
			
		}
	}
	#section-locations {
		.locations {
			> ul {
				margin-bottom: 30px;
			}
			section {
				ul {
					list-style: none;
					text-align: left;
					margin-left: 0;
					margin-bottom: 10px;
				}
			}
			@include breakpoint(small only) {
				> ul {
					> li {
						border-width: 1px;
						margin-bottom: 15px;
					}
				}
				figure {
					display: block;
					margin-bottom: 10px;
				}
			}
			@include breakpoint(medium up) {
				background: $primary-color;
			}
		}
	}
	
	#section-commodity {
		@extend #section-contents;
		.column-box {
			figure {
				margin-bottom: 10px;
			}
			a.btn {
				padding: 10px 50px;
			}
		}
	}
}


// 施工事例
// --------------------

main#portfolio {
	nav {
		margin-bottom: 10px;
	}
	.container {
		> h3 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 10px;
			border-bottom: 1px solid;
			padding-bottom: 5px;
			@include breakpoint(medium up) {
				font-size: 31px;
				padding-bottom: 5px;
				margin-bottom: 30px;
			}
		}
	}
	ul {
		list-style: none;
		margin: 0;
		&.portfolio-list {
			> li {
				margin-bottom: 15px;
			}
			section {
				border: 1px solid $medium-gray;
				padding: 10px;
				text-align: center;
			}
			a.btn {
				padding: 10px 50px;
			}
			h5 {
				font-weight: bold;
				border-bottom: 1px dotted $medium-gray;
				padding-bottom: 5px;
			}
			figure {
				margin-bottom: 10px;
				img {
					width: 100%;
				}
			}
			ul {
				text-align: left;
				margin-bottom: 10px;
			}
			@include breakpoint(medium up) {
				overflow: hidden;
				> li {
					float: left;
					width: 32%;
					margin-bottom: 20px;
					section {
						padding-bottom: 20px;
					}
					&:not(:last-child) {
						margin-right: 2%;
					}
				}
				figure {
					margin-bottom: 20px;
				}
			}
		}
		&.information {
			border: 4px solid $light-gray;
			padding: 14px;
			@include breakpoint(medium up) {
				padding: 30px;
			}
		}
	}
	.gallery-top {
		text-align: center;
		@include breakpoint(medium up) {
			margin-bottom: 20px;
		}
	}
	.gallery-thumbs {
		background: #333;
		padding: 6px 0;
		margin-bottom: 20px;
		.swiper-slide {
			width: 25%;
			opacity: .4;
		}
		.swiper-slide-thumb-active {
			opacity: 1;
		}
		@include breakpoint(large up) {
			padding: 10px 0;
		}
	}
}


// 会社概要
// --------------------

main#company {
	.section-image {
		background-image: url(../images/company/company_image01.jpg);
	}
	
	table {
		tbody {
			border: none;
		}
		tr {
			background: none;
			border-bottom: 1px solid $medium-gray;
			&:first-child {
				border-top: 1px solid $medium-gray;
			}
		}
		th,td {
			padding: 30px;
		}
		th {
			width: 30%;
			padding-left: 15%;
			span {
				display: block;
			}
		}
		td {
			padding-right: 15%;
		}
	}
	iframe {
		width: 100%;
		height: 450px;
		border: none;
	}
	@include breakpoint(small only) {
		table {
			tr,th,td {
				display: block;
				width: 100%;
				text-align: left;
				padding: 0;
			}
			tr {
				border: none !important;
				&:first-child {
					th {
						margin-top: 0;
					}
				}
			}
			th {
				margin-top: 20px;
				margin-bottom: 5px;
				span {
					display: inline-block;
				}
			}
			td {
				background: $light-gray;
				width: calc(100% + 20px);
				padding: 10px;
				margin-left: -10px;
				margin-right: -10px;
			}
		}
	}
}


// お問い合わせ
// --------------------

main#contact {
	.container {
		max-width: 800px;
	}
	.screen-reader-response {
		&[role] {
			background: $light-gray;
			padding: 20px 10px;
			margin-bottom: 30px;
			font-weight: bold;
			ul {
				display: none;
			}
			@include breakpoint(medium up) {
				text-align: center;
				padding: 30px;
			}
		}
	}
	.wpcf7-response-output {
		display: none;
	}
	form {
		label {
			font-weight: bold;
			color: $dark-gray;
			small {
				display: block;
			}
		}
		span.required {
			color: $alert-color;
			margin: 0 5px;
		}
		input[type="submit"] {
			@extend .button;
			display: block !important;
			margin: 0 auto !important;
			padding: 20px !important;
			width: 200px;
			border-radius: 3px !important;
		}
		select {
			margin-bottom: 0;
		}
		span.wpcf7-form-control-wrap {
			span[role] {
				color: $alert-color;
				font-weight: bold;
				font-size: 14px;
			}
		}
		> p {
			margin-bottom: 20px;
			> span {
				input, textarea {
					margin-bottom: 5px;
				}
			}
		}
		@include breakpoint(medium up) {
			> p:not(.text) {
				display: table;
				width: 100%;
				> label, > span {
					display: table-cell;
					vertical-align: middle;
				}
				> label {
					font-size: 16px;
					text-align: center;
					width: 30%;
				}
			}
			p.text {
				margin-bottom: 50px;
			}
		}
	}
}


// リクルート
// --------------------

main#recruit {
	#section-permanent {
		margin-bottom: 70px;
		@include breakpoint(medium up) {
			margin-bottom: 100px;
		}
		.section-image {
			background-image: url(../images/recruit/recruit_image01.jpg);
		}
	}
	#section-parttime {
		.section-image {
			background-image: url(../images/recruit/recruit_image02.jpg);
		}
	}
	#section-construction-permanent {
		.section-image {
			background-image: url(../images/recruit/recruit_image03.jpg);
		}
	}
	ul.column-box {
		display: flex;
		flex-direction: column;
		> li {
			float: none;
			display: flex;
			width: 100%;
		}
		@include breakpoint(medium up) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			> li {
				width: 48%;
				margin: 0;
				margin-bottom: 20px;
			}
		}
		@include breakpoint(large up) {
			> li {
				width: 32%;
			}
		}
	}
	ul.tab-group {
		list-style: none;
		text-align: center;
		margin-left: 0;
		margin-bottom: 30px;
		@include breakpoint(medium up) {
			margin-bottom: 100px;
		}
	}
	.tab {
		display: inline-block;
		width: 43%;
		padding: 10px;
		cursor: pointer;
		color: $primary-color;
		border: 1px solid $primary-color;
		&.is-active {
			background: $primary-color;
			color: $white;
		}
		&:not(.is-active) {
			&:hover {
				background: rgba($primary-color, .2);
				transition-duration: .2s;
			}
		}
		@include breakpoint(medium up) {
			width: 300px;
			padding: 20px;
			margin: 0 5px;
		}
	}
	.panel {
		display: none;
		&.is-show {
			display: block;
		}
	}
	ul.column-box {
  	section {
    	figure {
      	margin-bottom: 20px;
    	}
    	ul {
      	margin: 0;
      	list-style: none;
    	}
  	}
	}
	table {
  	margin-bottom: 30px;
  	td {
	  	ul {
		  	li:not(:last-child) {
			  	margin-bottom: 6px;
		  	}
	  	}
	  	ul:last-child {
		  	margin-bottom: 0;
	  	}
  	}
  	ul {
	  	li:not(:last-child) {}
  	}
  	@include breakpoint(medium up) {
    	margin-bottom: 70px;
  	}
		tbody {
			border: none;
		}
		tr {
			background: none;
			border-bottom: 1px solid $medium-gray;
			&:first-child {
				border-top: 1px solid $medium-gray;
			}
		}
		th,td {
			padding: 30px;
		}
		th {
			width: 30%;
			padding-left: 15%;
			span {
				display: block;
			}
		}
		td {
			padding-right: 15%;
	  	&.entry {
		  	text-align: left;
	  	}
			
		}
	}
	iframe {
		width: 100%;
		height: 450px;
		border: none;
	}
	@include breakpoint(small only) {
		table {
			tr,th,td {
				display: block;
				width: 100%;
				text-align: left;
				padding: 0;
			}
			tr {
				border: none !important;
				&:first-child {
					th {
						margin-top: 0;
					}
				}
			}
			th {
				margin-top: 20px;
				margin-bottom: 5px;
				span {
					display: inline-block;
				}
			}
			td {
				background: $light-gray;
				width: calc(100% + 20px);
				padding: 10px;
				margin-left: -10px;
				margin-right: -10px;
		  	&.entry {
			  	text-align: center;
			  	background: $white;
		  	}
				
			}
		}
	}
}

// エントリーフォーム
// --------------------

main#contact {
	ol.entry-flow {
		list-style: none;
		margin: 0 0 50px;
		li {
			background: $light-gray;
			padding: 15px;
			display: flex;
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			span {
				font-weight: bold;
				margin-right: 6px;
			}
		}
		@include breakpoint(medium up) {
			margin-bottom: 100px;
			li {
				padding: 30px;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				span {
					margin-right: 20px;
				}
			}
		}
	}
}

// ニュース
// --------------------

main#news {
	header {
		width: 100%;
		max-width: 750px;
		padding: 0 10px;
		margin-left: auto;
		margin-right: auto;
		h3 {
			font-weight: bold;
			font-size: 22px;
		}
		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			date {
				letter-spacing: 1px;
				color: $dark-gray;
				font-size: 13px;
			}
			label {
				background: $primary-color;
				border-radius: 3px;
				padding: 5px 10px;
				font-weight: bold;
				color: $white;
				font-size: 12px;
				line-height: 1;
				&.futsal {
					background: $secondary-color;
				}
			}
		}
	}
	.container {
		max-width: 750px;
		font-size: 15px;
		h4 {
			font-size: 18px;
			border-bottom: 1px dotted;
			font-weight: bold;
			padding-bottom: 8px;
		}
		h5 {
			font-size: 15px;
			font-weight: bold;
		}
		figure {
			padding: 15px;
			margin-bottom: 1rem;
		}
	}
	@include breakpoint(medium up) {
		header {
			padding: 0 30px;
			h2 {
				margin-bottom: 70px;
			}
			h3 {
				font-size: 26px;
				margin-bottom: 30px;
			}
		}
		.container {
			h4 {
				font-size: 22px;
			}
			h5 {
				font-size: 16px;
			}
		}
	}
}

