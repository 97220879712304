#global-footer {
  text-align: center;
  background: #333;
  .inner {
	  @extend .container;
	  padding-top: 10px !important;
	  padding-bottom: 10px !important;
	  color: $white;
  }
  .right {
	  img {
		  max-width: 60px; 
	  }
	  a:hover img {
		  opacity: .7;
	  }
  }
  h2 {
	  width: 200px;
	  margin: 0 auto;
  }
  h3 {
	  font-size: 13px;
	  &:after {
		  content: "";
		  display: block;
		  height: 1px;
		  width: 100%;
		  background: linear-gradient(45deg, $primary-color, $secondary-color);
		  margin: 15px 0;
	  }
  }
  dl {
	  font-size: 13px;
	  dd {
		  margin-bottom: 10px;
	  }
  }
  p {
	  margin-bottom: 0;
	  small {
		  font-size: 11px;
	  }
  }
  
  @include breakpoint(large up) {
	  .inner {
		  padding-top: 30px !important;
		  padding-bottom: 10px !important;
	  }
	  .left {
		  float: left;
		  width: 40%;
	  }
	  .right {
		  float: right;
	  }
	  h2 {
		  width: 254px;
	  }
	  h3:after {
		  margin: 30px 0;
	  }
	  dl {
		  text-align: left;
		  margin-bottom: 50px;
		  dt {
			  float: left;
			  width: 6em;
		  }
		  dd {
			  &:before {
				  content: "：";
			  }
		  }
	  }
  }
}



