main {
	display: block;
}
.clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}
span.bold {
	font-weight: bold;
}

svg {
	&.svg {
		display: none;
	}
}

.logo-header {
	fill: $primary-color;
}
.logo-footer {
	fill: $white;
}

.fadein {
  opacity : 0;
  transform : translate(0, 80px);
  transition : all 2s;
  &.right {
	  transform : translate(-80px, 0);
  }
  &.left {
	  transform : translate(80px, 0);
  }
	&.slidein {
	  opacity : 1;
	  transform : translate(0, 0);
	}
}


.container {
	width: 100%;
	max-width: $global-width;
	padding: 0 10px;
	margin: 0 auto;
	@include breakpoint(medium up) {
		padding: 0 30px;
	}
}

.text-set {
	margin-bottom: 40px;
	h2 {
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		line-height: 1.6;
		margin-bottom: 20px;
	}
	@include breakpoint(large up) {
		margin-bottom: 150px;
		h2 {
			font-size: 36px;
			margin-bottom: 60px;
			letter-spacing: 0.1em;
		}
		p {
			text-align: center;
			line-height: 2.5;
			span {
				display: block;
			}
		}
	}
}

.btn {
	position: relative;
	background: none;
	border: 1px solid $primary-color;
	color: $primary-color;
	transition: all 0.3s ease;
	z-index: 0;
	padding: 20px 50px;
	letter-spacing: 0.1em;
	display: inline-block;
	&:before {
		content: "";
		width: 0;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $primary-color;
		transition: all 0.3s ease;
		z-index: -1;
	}
	&:after {
		font-family: FontAwesome;
		content: "\f105";
		position: absolute;
		right: 10px;
	}
	&:hover {
		color: $white;
		&:before {
			width: 100%;
		}
	}
	&.white {
		border-color: $white;
		color: $white;
		&:before {
			background: $white;
		}
		&:hover {
			color: $primary-color;
		}
	}
}

ul.column-box {
	list-style: none;
	margin-left: 0;
	> li {
		margin-bottom: 20px;
	}
	section {
		border: 4px solid $light-gray;
		padding: 14px;
	}
	h2 {
		text-align: center;
		color: $primary-color;
		span {
			display: block;
			font-family: Niconne;
			font-size: 33px;
			letter-spacing: 0.1rem;
		}
		&:after {
			content: "";
			width: 1px;
			height: 22px;
			margin: 15px auto;
			background: $primary-color;
			display: block;
		}
	}
	ol {
		margin-bottom: 10px;
	}
	@include breakpoint(medium up) {
		overflow: hidden;
		> li {
			float: left;
			width: 32%;
			margin-right: 2%;
			&:last-child {
				margin-right: 0;
			}
		}
		section {
			padding: 24px;
		}
		h2 {
			font-size: 26px;
			span {
				font-size: 40px;
			}
			&:after {
				height: 30px;
				margin: 24px auto;
			}
		}
	}
}

.heading {
	text-align: center;
	font-weight: 900;
	margin-bottom: 40px;
	color: $secondary-color;
	span,small {
		display: block;
	}
	span {
		font-size: 46px;
		letter-spacing: 0.1rem;
		color: $secondary-color;
	}
	small {
		color: $black;
		font-size: 14px;
		&:before {
			display: block;
			content: "";
			height: 1px;
			width: 50px;
			background: $secondary-color;
			margin: 0 auto 20px;
		}
	}
}
