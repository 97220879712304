body.home {
	overflow-x: hidden;
	#global-header {
		&:not(.fixed) {
			.logo-header {
				fill: $white;
			}
			#nav-toggle {
				span {
					background: $white;
				}
			}
			@include breakpoint(large up) {
				nav {
					> ul {
						> li {
							> a, > span {
								color: $white !important;
							}
						}
					}
				}
			}
		}
	}
	main {
		display: block;
		background: $white;
		padding: 100px 0;
	}
	
	#welcome {
		position: relative;
		overflow: hidden;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		z-index: -20;
		height: 100vh;
		video {
			min-height: 100vh;
			display: none;
			&:after {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				background: rgba($black, .8);
			}
			@include breakpoint(large up) {
				display: block;
			}
		}
		.hero-image {
			background: url(../images/front-page/hero_01.jpg) no-repeat;
			background-size: cover;
			background-position: center center;
			width: 100%;
			height: 100vh;
			@include breakpoint(large up) {
				display: none;
			}
		}
		.overlay {
			@include breakpoint(large up) {			
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100vh;
				background-color: rgba($black, .6);
				background-image: linear-gradient(
			    -45deg,
			    rgba($black, 0) 25%,
			    $black 25%, $black 50%,
			    rgba($black, 0) 50%, rgba($black, 0) 75%,
			    $black 75%, $black
			  );
			  background-size: 4px 4px;
			}
		}
		.slogan {
			text-align: left;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 10;
			h2 {
				color: $secondary-color;
				font-weight: bold;
				font-size: 22px;
				letter-spacing: 0.1em;
				line-height: 2;
				span {
					display: block;
				}
			}
			h3 {
				color: $white;
				font-weight: bold;
				font-size: 13px;
				line-height: 1.6;
				span {
					display: block;
				}
			}
			@include breakpoint(large up) {
				text-align: center;
				h2 {
					font-size: 52px;
					font-weight: 900;
					line-height: 1.6;
					margin-bottom: 20px;
					padding: 0 30px;
					span {
						display: inline-block;
					}
				}
				h3 {
					font-size: 18px;
					letter-spacing: 0.1em;
					span {
						display: inline;
					}
				}
			}
		}
	}
	
	
	#mission {
		@extend .container;
		margin-bottom: 150px;
		section {
			margin-bottom: 70px;
			h2 {
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				line-height: 1.6;
				margin-bottom: 20px;
/*
				&:before { content: "“"; }
				&:after { content: "”"; }
*/
			}
			p {
				line-height: 2;
			}
			.text-center {
				margin-top: 30px;
			}
		}
		@include breakpoint(large up) {
			margin-bottom: 300px;
			section {
				margin-bottom: 150px;
				h2 {
					font-size: 36px;
					margin-bottom: 60px;
					letter-spacing: 0.1em;
				}
				p {
					text-align: center;
					line-height: 2.5;
					span {
						display: block;
					}
				}
			}
		}
	}
	
	
	#service-futsal, #service-construction {
		position: relative;
		img {
			width: 100%;
			margin-bottom: 20px;
		}
		> span {
			font-size: 46px;
			font-weight: 900;
			color: $secondary-color;
			line-height: 1;
			letter-spacing: 0.1em;
			position: absolute;
			left: 10px;
			&:nth-child(2) {
				bottom: 100%;
			}
			&:nth-child(3) {
				top: 0;
				color: $white;
			}
		}
		> div {
			text-align: center;
			h2 {
				font-size: 20px;
				font-weight: bold;
				color: $primary-color;
			}
			h3 {
				font-size: 13px;
				&:after {
					content: "";
					width: 30px;
					height: 1px;
					background: $medium-gray;
					display: block;
					margin: 10px auto;
				}
			}
			ul {
				list-style: none;
				margin-left: 0;
				margin-bottom: 30px;
				padding: 0 10px;
			}
			p span {
				display: block;
			}
		}
		@include breakpoint(small only) {
			.fadein {
				transform: translate(0, 0);
				opacity: 1;
			}
		}
		@include breakpoint(large up) {
			img {
				margin-bottom: 0;
			}
			> span {
				font-size: 110px;
			}
			> div {
				position: absolute;
				right: 0;
				top: 0;
				background: rgba($white, .9);
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 38%;
				h2 {
					font-size: 36px;
				}
			}
		}
	}
	
	
	#service-futsal {
		margin-bottom: 150px;
		@include breakpoint(large up) {
			margin-bottom: 300px;
			> span:nth-child(3) {
				left: 40px;
			}
		}
	}
	
	
	#service-construction {
		margin-bottom: 150px;
		> span {
			color: $primary-color;
		}
		> div {
			right: inherit;
			left: 0;
		}
		@include breakpoint(large up) {
			> span {
				left: inherit;
				right: 10px;
				&:nth-child(2) {
					right: 40px;
				}
			}
		}
	}
	
	
	#section-movie {
		@extend .container;
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			list-style: none;
			margin: 0;
			li {
				margin-bottom: 15px;
				@include breakpoint(medium up) {
					width: 48%;
				}
				@include breakpoint(large up) {
					width: 24%;
				}
				iframe {
					width: 100%;
				}
			}
		}
	}
	
	#section-news {
		ul.news-list {
			list-style: none;
			margin: 0;
			li {
				border-bottom: 1px solid $medium-gray;
				font-size: 13px;
				padding: 10px;
				&:first-child {
					border-top: 1px solid $medium-gray;
				}
				label {
					display: inline-block;
					font-size: 11px;
					font-weight: bold;
					color: $white;
					background: $primary-color;
					border-radius: 3px;
					padding: 3px 5px;
					line-height: 1;
					width: 7em;
					text-align: center;
					&.futsal {
						background: $secondary-color;
					}
				}
				date {
					display: inline-block;
					letter-spacing: 1px;
					color: $dark-gray;
					margin-left: 6px;
				}
				> span, > a {
					display: block;
					margin-top: 8px;
				}
			}
			@include breakpoint(medium up) {
				li {
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
					padding: 20px;
					label {
						font-size: 12px;
						min-width: 8em;
						padding: 5px;
					}
					> span, > a {
						margin-top: 0;
						margin-left: 20px;
						font-size: 15px;
					}
				}
			}
			@include breakpoint(large up) {
				li {
					padding: 20px 120px;
					label {
						padding: 7px 10px;
						min-width: 10em;
					}
					date {
						margin-left: 20px;
					}
				}
			}
		}
	}
}
